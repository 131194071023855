a {
  text-decoration: none;
  color: black;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Playfair Display', serif;
}
